<template>
  <div>
    <el-collapse v-model="activeTab" accordion>
      <el-collapse-item disabled :name="collapseItemIndexes[0]">
        <template #title>
          <CollapseItemHeaderNumber :number="1" />
          {{ $t('createBusinessModal.businessSearch') }}
        </template>
        <div>
          <GooglePlacesSearchInputResults
            :search-results="searchResults"
            :input-validation-state="inputValidationState"
            @on-item-select="onItemSelect"
            @on-input-value-change="onInputValueChange"
            @on-reset-search-results="onResetSearchResults"
          />

          <div class="d-flex flex-row-reverse">
            <Button size="large" @click="onNextButtonClick"> {{ $t('createBusinessModal.nextButton') }}</Button>
          </div>
        </div>
      </el-collapse-item>

      <el-collapse-item disabled :name="collapseItemIndexes[1]">
        <template #title>
          <CollapseItemHeaderNumber :number="2" />
          {{ $t('createBusinessModal.moreInfo') }}
        </template>

        <CreateBusinessForm
          :form="form"
          :loading="loading"
          :has-country-code="hasCountryCode"
          @on-back-click="onBackButtonClick"
          @on-form-submit="createNewBusiness"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, computed } from 'vue';

import { Button } from '@/modules/core';

import CreateBusinessForm from '../components/createBusinessForm';
import GooglePlacesSearchInputResults from '../components/googlePlacesSearchInputResults';
import CollapseItemHeaderNumber from '../components/collapseItemHeaderNumber';

import { useGooglePlaces } from '../../compositions/useGooglePlaces';
import { createBusiness } from '../../compositions/businesses';
import { createBusinessFormDefaultValues } from '../../constants';

export default {
  name: 'businessInfoWrapper',
  components: { Button, CreateBusinessForm, GooglePlacesSearchInputResults, CollapseItemHeaderNumber },
  props: {
    form: {
      type: Object,
      default: () => createBusinessFormDefaultValues,
    },
  },
  emits: ['on-reset-search-results', 'on-close'],
  setup(props, { emit }) {
    const { $message, $i18n, $route } = getCurrentInstance().proxy;
    const collapseItemIndexes = ['collapse-item-index-one', 'collapse-item-index-two'];

    const searchQuery = ref('');
    const activeTab = ref(collapseItemIndexes[0]);
    const hasCountryCode = ref(true);
    const inputValidationState = reactive({ validState: true });

    const { results: searchResults } = useGooglePlaces(computed(() => searchQuery.value));
    const { mutate: createNewBusinessHook, loading, onDone: onCreateNewBusinessDoneHook } = createBusiness();

    const findCountryCode = (selectedItem) => {
      const countryCodeAddressComponent = selectedItem.addressComponents.find(({ types }) => {
        const [country] = types;
        return country === 'country';
      });

      if (countryCodeAddressComponent) {
        hasCountryCode.value = true;
        return countryCodeAddressComponent.shortText;
      }

      hasCountryCode.value = false;
      return undefined;
    };

    const onNextButtonClick = () => {
      activeTab.value = collapseItemIndexes[props.form.name ? 1 : 0];
      if (activeTab.value === collapseItemIndexes[0]) {
        inputValidationState.validState = false;
      }
    };

    const onBackButtonClick = () => {
      activeTab.value = collapseItemIndexes[0];
      inputValidationState.validState = true;
    };

    const populateForm = (selectedItem) => {
      props.form.name = selectedItem?.displayName?.text;
      props.form.legalName = '';
      props.form.number = '';
      props.form.phone = selectedItem?.internationalPhoneNumber;
      props.form.email = '';
      props.form.tenantEmail = '';
      props.form.logoUrl = '';
      props.form.countryCode = findCountryCode(selectedItem);
      props.form.address = selectedItem?.formattedAddress;
      props.form.defaultAddress = selectedItem?.formattedAddress;
      props.form.websiteUri = selectedItem?.websiteUri;
      props.form.summary = selectedItem?.editorialSummary?.text;
      props.form.location = selectedItem?.location;
      props.form.googleMapsUri = selectedItem?.googleMapsUri;
      props.form.capabilities = {
        restaurant: false,
        supplier: false,
        bookkeeping: false,
      };
    };

    const clearForm = () => {
      Object.keys(createBusinessFormDefaultValues).forEach((key) => {
        props.form[key] = createBusinessFormDefaultValues[key];
      });
    };

    const onItemSelect = (selectedItem) => {
      clearForm();
      populateForm(selectedItem);
    };
    const onInputValueChange = (value) => (searchQuery.value = value);
    const onResetSearchResults = () => {
      searchQuery.value = null;
      emit('on-reset-search-results');
    };
    const createNewBusiness = () => {
      const businessToCreate = {
        name: props.form.name,
        legalName: props.form.legalName,
        number: props.form.number,
        capabilities: props.form.capabilities,
        countryCode: props.form.countryCode,
        parentId: $route.params.tenantId,
        contact: {
          phone: props.form.phone,
          address: props.form.address,
        },
      };

      if (props.form.logoUrl && props.form.logoUrl.length) {
        businessToCreate.logoUrl = props.form.logoUrl;
      }

      if (props.form.email) {
        businessToCreate.contact.email = props.form.email;
      }

      if (props.form.capabilities?.restaurant) {
        businessToCreate.tenant = props.form.tenantEmail;
      }

      createNewBusinessHook({ businessCreateParams: businessToCreate });
    };

    onCreateNewBusinessDoneHook(() => {
      $message.success($i18n.t('createBusinessModal.businessCreated'));
      emit('on-close');
    });

    return {
      loading,
      activeTab,
      searchResults,
      hasCountryCode,
      collapseItemIndexes,
      inputValidationState,
      onItemSelect,
      createNewBusiness,
      onNextButtonClick,
      onBackButtonClick,
      onInputValueChange,
      onResetSearchResults,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  i {
    display: none;
  }

  .el-collapse {
    border: 0;

    .el-collapse-item__header {
      color: $typography-secondary;
      font-size: 1rem;
      cursor: default !important;
      height: 1.5rem;
    }
    .el-collapse-item__header.is-active {
      color: $typography-primary;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .el-collapse-item__header,
    .el-collapse-item__wrap {
      border: 0;
      margin: 1rem;
    }

    .el-collapse-item {
      border-radius: 0.25rem;
      border: 1px solid $gray-outline;
      margin-bottom: 1rem;
    }
    .el-collapse-item:last-of-type {
      margin-bottom: 0;
    }

    svg.circular {
      width: 25px;
      margin-top: 7px;
      filter: grayscale(100%) saturate(0%);
    }
  }
}
</style>
