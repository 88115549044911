<template>
  <div>
    <el-dialog :visible="visible" :show-close="false" destroy-on-close width="1131px" :z-index="1020">
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="title">{{ $t('createBusinessModal.title') }}</h3>

          <Button type="text" class="p-1 text-typography-primary close-btn" @click="onClose">
            <CloseIcon />
          </Button>
        </div>
      </template>

      <div class="d-flex flex-row justify-content-between gap-4">
        <div class="business-info-wrapper">
          <BusinessInfoWrapper
            :form="form"
            @on-reset-search-results="onResetSearchResults"
            @on-close="onCloseConfirm"
          />
        </div>

        <div class="map-wrapper">
          <MapWrapper :form="form" />
        </div>
      </div>
    </el-dialog>

    <ActionsModal
      v-if="quitWithoutSaving"
      :toggle-dialog="quitWithoutSaving"
      :title="$t('createBusinessModal.confirmModal.title')"
      :content="$t('createBusinessModal.confirmModal.content')"
      :confirm-button-text="$t('createBusinessModal.confirmModal.confirm')"
      :cancel-button-text="$t('createBusinessModal.confirmModal.cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="quitWithoutSaving = false"
      @on-close="quitWithoutSaving = false"
      @on-confirm="onCloseConfirm"
    />
  </div>
</template>
<script>
import { ref, reactive } from 'vue';

import { ActionsModal, Button, DIALOG_TYPES } from '@/modules/core';

import { CloseIcon } from '@/assets/icons';

import BusinessInfoWrapper from './views/businessInfoWrapper';
import MapWrapper from './views/mapWrapper';
import { createBusinessFormDefaultValues } from '../constants';

export default {
  name: 'CreateBusinessModal',
  components: { BusinessInfoWrapper, MapWrapper, ActionsModal, CloseIcon, Button },
  props: {
    visible: { type: Boolean, default: false },
  },
  emits: ['on-close'],
  setup(_, { emit }) {
    const quitWithoutSaving = ref(false);

    const form = reactive({ ...createBusinessFormDefaultValues });

    const onCloseConfirm = () => {
      quitWithoutSaving.value = false;
      onResetSearchResults();
      emit('on-close');
    };

    const onClose = () => {
      if (form?.name) {
        quitWithoutSaving.value = true;
      } else {
        onCloseConfirm();
      }
    };

    const onResetSearchResults = () => {
      Object.keys(createBusinessFormDefaultValues).forEach((key) => {
        form[key] = createBusinessFormDefaultValues[key];
      });
    };

    return {
      DIALOG_TYPES,
      form,
      quitWithoutSaving,
      onClose,
      onCloseConfirm,
      onResetSearchResults,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-dialog {
    margin-top: 10vh !important;
    border-radius: 0.5rem;
  }
  .el-dialog__body {
    padding: 1rem;
  }
}

.title {
  font-size: 1.5rem;
}
.business-info-wrapper {
  width: 100%;
  height: 43.75rem;
}

.map-wrapper {
  width: 25rem;
  height: 43.75rem;
}

.close-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>
