<template>
  <img v-if="googleMapsStaticUrl" :src="googleMapsStaticUrl" alt="Google Map" @click="redirect" />
</template>
<script>
export default {
  name: 'StaticGoogleMapsImage',
  props: {
    googleMapsStaticUrl: { type: String, default: null },
    redirectUrl: { type: String, required: true },
  },
  setup(props) {
    const redirect = () => window.open(props.redirectUrl, '_blank');
    return {
      redirect,
    };
  },
};
</script>
<style lang="scss" scoped>
img {
  cursor: pointer;
}
</style>
