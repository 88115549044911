<template>
  <div class="map-wrapper text-break" :class="{ empty: !form?.name }">
    <div v-if="form?.name" class="p-4 relative">
      <StaticGoogleMapsImage
        v-if="googleMapsStaticUrl"
        :google-maps-static-url="googleMapsStaticUrl"
        :redirect-url="form.googleMapsUri"
      />
      <CreateBusinessAdditionalInfo :form="form" />
    </div>
    <div v-else>
      <p class="no-result-selected">{{ $t('createBusinessModal.mapWrapper.emptyResults') }}</p>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import { useGoogleMapsUrlCreator } from '../../compositions/useGooglePlaces';
import CreateBusinessAdditionalInfo from '../components/createBusinessAdditionalInfo';
import StaticGoogleMapsImage from '../components/staticGoogleMapsImage';
import { createBusinessFormDefaultValues } from '../../constants';

export default {
  name: 'mapWrapper',
  components: { CreateBusinessAdditionalInfo, StaticGoogleMapsImage },
  props: {
    form: {
      type: Object,
      default: () => createBusinessFormDefaultValues,
    },
  },
  setup(props) {
    const googleMapsLocationCoordinates = computed(() => {
      if (props?.form?.location) {
        const { latitude, longitude } = props.form.location;
        return `${latitude},${longitude}`;
      }
      return null;
    });

    const { googleMapsStaticUrl } = useGoogleMapsUrlCreator(googleMapsLocationCoordinates);

    return {
      googleMapsStaticUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.map-wrapper {
  height: 100%;
  border-radius: 0.25rem;
  border: 1px solid $gray-outline;
  overflow: scroll;
}

.map-wrapper.empty {
  overflow: hidden;
  background: $gray;

  .no-result-selected {
    color: $typography-secondary;
    text-align: center;
    line-height: 50;
  }
}
</style>
