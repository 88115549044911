<template>
  <div class="search-input-wrapper">
    <el-input v-model="inputValue" :clearable="false" @input="onInputValueChange">
      <template #prefix>
        <div class="input-icon">
          <SearchIcon width="16px" height="16px" />
        </div>
      </template>
      <template #suffix>
        <div v-if="inputValue" class="input-icon close-icon-wrapper" @click="onResetSearchResults">
          <RemoveIcon width="16px" height="16px" />
        </div>
      </template>
    </el-input>

    <p v-if="inputValue && searchResults.length" class="wrong-results my-2 text-break">
      {{ $t('createBusinessModal.searchResults.wrongResults') }}
    </p>
    <p v-if="inputValue && !searchResults.length" class="text-center wrong-results no-results text-break">
      <a href="https://www.google.com/maps" class="wrong-results" target="_blank">
        {{ $t('createBusinessModal.searchResults.noResults') }}<span>Google Maps</span>
        <ExternalLinkIcon class="mx-1" />
      </a>
    </p>
    <p v-if="!inputValue" class="text-center wrong-results no-results text-break">
      {{ $t('createBusinessModal.searchResults.startSearching') }}
    </p>

    <div v-if="inputValue.length" class="search-result">
      <p v-for="(result, index) in searchResults" :key="result.name">
        <span
          v-if="index < 5"
          class="search-result-item"
          :class="{ selected: index == selectedItemIndex }"
          @click="() => onItemSelect(index)"
        >
          <TruncatedText class="p-2">
            <MapPinIcon class="map-pin-icon" />
            <span class="search-result-item-title">{{ result.displayName.text }} </span>
            <span class="search-result-item-address">{{ result.formattedAddress }}</span></TruncatedText
          >
        </span>
      </p>
      <p
        v-if="selectedItemIndex === null && inputValidationState.validState === false"
        class="mt-4 no-business-selected"
      >
        <NoticeIcon width="16" height="16" />
        {{ $t('createBusinessModal.searchResults.businessNotSelectedValidation') }}
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { SearchIcon, RemoveIcon, MapPinIcon, ExternalLinkIcon, NoticeIcon } from '@/assets/icons';
import { TruncatedText } from '@/modules/core';

export default {
  name: 'GooglePlacesSearchInputResults',
  components: { SearchIcon, RemoveIcon, MapPinIcon, TruncatedText, ExternalLinkIcon, NoticeIcon },
  props: {
    searchResults: { type: Array, default: () => [] },
    inputValidationState: { type: Object, default: () => ({ validState: true }) },
  },
  emits: ['on-input-value-change', 'on-item-select', 'on-reset-search-results'],
  setup(props, { emit }) {
    const selectedItemIndex = ref(null);
    const inputValue = ref('');

    const onItemSelect = (index) => {
      selectedItemIndex.value = index;
      emit('on-item-select', props.searchResults[index]);
    };

    const onResetSearchResults = () => {
      inputValue.value = '';
      selectedItemIndex.value = null;
      props.inputValidationState.validState = true;
      emit('on-reset-search-results');
    };

    const onInputValueChange = (value) => {
      if (!value) onResetSearchResults();
      emit('on-input-value-change', value);
    };

    return {
      inputValue,
      selectedItemIndex,
      onItemSelect,
      onResetSearchResults,
      onInputValueChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.search-input-wrapper {
  height: 32.1rem;
  .wrong-results {
    color: $typography-secondary;
    &:hover {
      text-decoration: none;
      cursor: default;
      span {
        &:hover {
          cursor: pointer;
        }
      }
    }
    span {
      text-decoration: underline;
    }
  }
  .no-results {
    max-width: 460px;
    margin: 0 auto !important;
    margin-top: 2.75rem !important;
  }
  .search-result-item {
    &:hover {
      cursor: pointer;
      > div {
        border-radius: 0.25rem;
        background: $gray;
      }
    }
  }
  .search-result {
    margin-top: 1rem;

    .no-business-selected {
      color: $checkbox-error;

      svg {
        margin-bottom: 2px;
      }
    }
  }

  .search-result-item.selected {
    > div {
      border-radius: 0.25rem;
      background: $doghnut-gray;
    }
  }

  .map-pin-icon {
    margin-inline-end: 1rem;
    margin-bottom: 0.125rem;
    color: $typography-secondary;
  }

  .search-result-item-title {
    color: $typography-primary;
  }

  .search-result-item-address {
    color: #71767d;
  }

  .input-icon {
    position: relative;
    top: 0.375rem;
    margin: 0 0.25rem;
  }
  .close-icon-wrapper {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
